<template>
  <div class="page">
    <div class="box1">
      <div class="main_box">
        <div class="ttl">定制化服务解决方案</div>
        <div class="sub_ttl">为企业量身打造信息化解决方案</div>
        <a
          class="btn"
          href="http://p.qiao.baidu.com/cps/chat?siteId=13160731&userId=27403999&siteToken=839057cc7afae96bcdd508339dc7a783"
          target="_blank"
        >
          咨询服务
        </a>
      </div>
    </div>
    <div class="box2">
      <div class="main_box">
        <div class="ttl">企业专属定制</div>
        <div class="sub_ttl">
          企业数据私有化部署，打造企业专属大数据指挥中心
        </div>
      </div>
      <div class="pic_bg" />
    </div>
    <div class="box3">
      <div class="title">专家级服务流程</div>
      <div class="main_box">
        <img class="pic" src="@/assets/img/流程2.png" alt="流程" />
      </div>
    </div>
    <div class="box4">
      <div class="title">定制化服务案例 - 电子合同</div>
      <div class="main_box">
        <div class="row">
          <div class="hd" flex="cross:center">
            <div class="s">Step1</div>
            <div class="t">客户需求调研示例：</div>
          </div>
          <img
            class="cnt"
            src="@/assets/img/step1.jpg"
            alt="客户需求调研示例"
          />
        </div>
        <div class="row">
          <div class="hd" flex="cross:center">
            <div class="s">Step2</div>
            <div class="t">解决方案 - 业务流程示例：</div>
          </div>
          <img
            class="cnt"
            src="@/assets/img/step2.png"
            alt="解决方案 - 业务流程示例"
          />
        </div>
        <div class="row">
          <div class="hd" flex="cross:center">
            <div class="s">Step3</div>
            <div class="t">定制化产品交付示例：</div>
          </div>
          <div class="cnt">
            <img
              class="p"
              src="@/assets/img/定制化.jpg"
              alt="定制化产品交付示例"
            />
            <div class="info">
              <div class="ttl">电子合同定制化案例</div>
              <div class="sub_ttl">
                在线选择电子合同模版，系统根据考勤规则和工人信息自动生成合同，快速完成签约。
              </div>
              <a
                class="btn"
                href="http://p.qiao.baidu.com/cps/chat?siteId=13160731&userId=27403999&siteToken=839057cc7afae96bcdd508339dc7a783"
                target="_blank"
              >
                咨询服务
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="trial_bar">
      <div class="main_box" flex="main:justify cross:center">
        <div class="ttl">为您打造一套数智工程管理解决方案</div>
        <div class="btn" @click="showTrialModal">申请体验</div>
      </div>
    </div>
    <Footer />
  </div>
</template>

<script>
import { mapActions } from "vuex";
import Footer from "@/components/Footer.vue";

export default {
  components: {
    Footer,
  },
  data() {
    return {};
  },
  methods: {
    ...mapActions(["showTrialModal"]),
  },
};
</script>

<style lang="scss" scoped>
@use "sass:math";
@import "@/theme.scss";

.box1 {
  background: url("~@/assets/bg/banner 7.jpg") no-repeat center center;
  background-size: cover;
  height: 32rem;
  overflow: hidden;

  .ttl {
    margin-top: 13.8rem;
    font-size: 2rem;
    font-weight: bold;
    color: #fff;
    line-height: 2.8rem;
    letter-spacing: 0.2rem;
  }

  .sub_ttl {
    margin-top: 1.1rem;
    font-size: 1rem;
    color: #fff;
    line-height: 1.5rem;
  }

  .btn {
    margin-top: 3rem;
    display: block;
    width: 6.6rem;
    height: 2rem;
    background-color: #2574ff;
    border-radius: 0.4rem;
    text-decoration: none;
    font-size: 0.9rem;
    font-weight: bold;
    text-align: center;
    color: #fff;
    line-height: 2rem;

    &:hover {
      background-color: #045af2;
    }
  }
}

.box2 {
  position: relative;
  overflow: hidden;
  background-color: #fff;

  .main_box {
    position: absolute;
    left: 50%;
    top: 8rem;
    margin-left: #{math.div($mainWidth, -2)};

    .ttl {
      font-size: 2rem;
      font-weight: bold;
      text-align: center;
      color: #1a1a1a;
      line-height: 3rem;
    }

    .sub_ttl {
      margin-top: 1rem;
      font-size: 1rem;
      text-align: center;
      color: #4a4a4a;
      line-height: 1.5rem;
    }
  }

  .pic_bg {
    background: url("~@/assets/img/企业专属定制.png") no-repeat center center;
    background-size: auto 100%;
    height: 63.5rem;
  }
}

.box3 {
  overflow: hidden;
  background-color: #f0f1f6;
  padding-top: 7rem;

  .title {
    font-size: 2rem;
    font-weight: bold;
    text-align: center;
    color: #1a1a1a;
    line-height: 3rem;
  }

  .main_box {
    padding: 5rem 0 5.5rem;

    .pic {
      display: block;
      width: 100%;
    }
  }
}

.box4 {
  overflow: hidden;
  background-color: #fff;
  padding: 7rem 0 6.4rem;

  .title {
    font-size: 2rem;
    font-weight: bold;
    text-align: center;
    color: #1a1a1a;
    line-height: 3rem;
  }

  .main_box {
    margin-top: 5.1rem;

    .row {
      margin-bottom: 5rem;

      &:last-child {
        margin-bottom: 0;
      }

      .hd {
        .s {
          width: 5.1rem;
          height: 2rem;
          line-height: 2rem;
          background-color: #2574ff;
          border-radius: 0.2rem;
          text-align: center;
          font-size: 1rem;
          color: #fff;
        }

        .t {
          margin-left: 1rem;
          font-size: 1.5rem;
          font-weight: bold;
          color: #4a4a4a;
          line-height: 2.25rem;
        }
      }

      .cnt {
        margin-top: 3rem;
        position: relative;
        display: block;
        width: 100%;

        .p {
          display: block;
          width: 100%;
        }

        .info {
          position: absolute;
          left: 5rem;
          top: 0;
        }

        .ttl {
          margin-top: 4.5rem;
          font-size: 2rem;
          font-weight: bold;
          color: #1a1a1a;
          line-height: 3rem;
          letter-spacing: 0.2rem;
        }

        .sub_ttl {
          margin-top: 0.9rem;
          font-size: 0.8rem;
          color: #1a1a1a;
          line-height: 1.2rem;
          width: 22rem;
        }

        .btn {
          margin-top: 7.2rem;
          display: block;
          width: 6.6rem;
          height: 2rem;
          background-color: #2574ff;
          border-radius: 0.4rem;
          text-decoration: none;
          font-size: 0.9rem;
          font-weight: bold;
          text-align: center;
          color: #fff;
          line-height: 2rem;

          &:hover {
            background-color: #045af2;
          }
        }
      }
    }
  }
}

.trial_bar {
  background-color: #f0f1f6;
  padding: 6rem 0 1.6rem;

  .main_box {
    background: url("~@/assets/bg/申请体验背景.png") no-repeat center center;
    background-size: cover;
    height: 7rem;
  }

  .ttl {
    margin-left: 4rem;
    font-size: 1.8rem;
    font-weight: bold;
    color: #fff;
    line-height: 2.5rem;
    letter-spacing: 0.15rem;
  }

  .btn {
    margin-right: 4rem;
    width: 7rem;
    height: 2.4rem;
    background-color: #fff;
    border-radius: 0.5rem;
    font-size: 1rem;
    font-weight: bold;
    text-align: center;
    color: #0078f0;
    line-height: 2.4rem;
    cursor: pointer;

    &:hover {
      background-color: #dce8ff;
    }
  }
}

.main_box {
  width: $mainWidth;
  margin: auto;
}
</style>
